<div class="container" (click)="switchDropdown($event)">
  <app-status-badge
    [status]="order.status"
    [label]="'orderStatus.' + order.status.code | translate"
  >
    @if (
      [OrderStatus.SEARCHING_VEHICLE, OrderStatus.AWAITING_APPROVAL].includes(
        order.status.code
      ) && order.rejectedVehicles
    ) {
      <span class="description">
        Odrzucone ({{ order.rejectedVehicles.length }})
        <app-tooltip
          [message]="order.rejectedVehicles.join(', ')"
        ></app-tooltip>

        <app-icon
          icon="delete"
          [size]="14"
          (click)="clearRejections(); $event.stopPropagation()"
        ></app-icon>
      </span>
    } @else if (
      [OrderStatus.IN_PROGRESS, OrderStatus.SOLD].includes(order.status.code)
    ) {
      <span class="description">
        @if (order.unloadingStarted) {
          <app-icon
            icon="clock"
            [size]="8"
            [title]="
              (order.unloadingStart | date: 'HH:mm, d MMM y' | titlecase) ||
              '??'
            "
          ></app-icon>
          Trwa rozładunek
        } @else if (order.wasLoaded) {
          <app-icon icon="checkbox" [size]="8"></app-icon>
          Załadowano
        } @else if (order.loadingStarted) {
          <app-icon
            icon="clock"
            [size]="8"
            [title]="
              (order.loadingStart | date: 'HH:mm, d MMM y' | titlecase) || '??'
            "
          ></app-icon>
          Trwa załadunek
        } @else {
          Przed załadunkiem
        }
      </span>
    } @else if (order.status.code === OrderStatus.UNASSIGNED) {
      <app-order-unnassignment-reasons
        [order]="order"
      ></app-order-unnassignment-reasons>
    }
  </app-status-badge>

  <app-icon
    [icon]="dropdown() ? 'chevron-up' : 'chevron-down'"
    [width]="10"
  ></app-icon>
</div>

@if (dropdown()) {
  <div class="dropdown">
    @if (
      ![
        OrderStatus.SEARCHING_VEHICLE,
        OrderStatus.AWAITING_APPROVAL,
        OrderStatus.UNASSIGNED
      ].includes(order.status.code)
    ) {
      <li (click)="changeStatus($event, OrderStatusChange.NEW)">
        <app-status-badge
          [status]="{ code: OrderStatusChange.NEW, color: BadgeColor.RED }"
          label="Nowe"
        ></app-status-badge>
      </li>
    }

    @if (
      order.status.code !== OrderStatus.IN_PROGRESS || order.wasLoaded !== false
    ) {
      <li (click)="changeStatus($event, OrderStatusChange.ASSIGNED)">
        <app-status-badge
          [status]="{
            code: OrderStatusChange.ASSIGNED,
            color: BadgeColor.BLUE
          }"
          label="W realizacji"
        >
          Przed załadunkiem
        </app-status-badge>
      </li>
    }

    @if (
      order.status.code !== OrderStatus.IN_PROGRESS ||
      (order.loadingStarted !== true && order.wasLoaded !== true)
    ) {
      <li (click)="changeStatus($event, OrderStatusChange.LOADING)">
        <app-status-badge
          [status]="{ code: OrderStatusChange.LOADING, color: BadgeColor.BLUE }"
          label="W realizacji"
        >
          <span class="description">
            <app-icon icon="clock" [size]="8"></app-icon>
            Trwa załadunek
          </span>
        </app-status-badge>
      </li>
    }

    @if (
      order.status.code !== OrderStatus.IN_PROGRESS || order.wasLoaded !== true
    ) {
      <li (click)="changeStatus($event, OrderStatusChange.LOADED)">
        <app-status-badge
          [status]="{ code: OrderStatusChange.LOADED, color: BadgeColor.BLUE }"
          label="W realizacji"
        >
          <span class="description">
            <app-icon icon="checkbox" [size]="8"></app-icon>
            Załadowano
          </span>
        </app-status-badge>
      </li>
    }

    @if (
      order.status.code !== OrderStatus.IN_PROGRESS ||
      (order.unloadingStarted !== true && order.wasUnloaded !== true)
    ) {
      <li (click)="changeStatus($event, OrderStatusChange.UNLOADING)">
        <app-status-badge
          [status]="{
            code: OrderStatusChange.UNLOADING,
            color: BadgeColor.BLUE
          }"
          label="W realizacji"
        >
          <span class="description">
            <app-icon icon="clock" [size]="8"></app-icon>
            Trwa rozładunek
          </span>
        </app-status-badge>
      </li>
    }

    @if (order.status.code !== OrderStatus.FINISHED) {
      <li (click)="changeStatus($event, OrderStatusChange.FINISHED)">
        <app-status-badge
          [status]="{ code: OrderStatusChange.NEW, color: BadgeColor.GREEN }"
          label="Zrealizowane"
        ></app-status-badge>
      </li>
    }

    @if (order.status.code !== OrderStatus.SOLD) {
      <li (click)="changeStatus($event, OrderStatusChange.SOLD)">
        <app-status-badge
          [status]="{ code: OrderStatusChange.SOLD, color: BadgeColor.YELLOW }"
          label="Sprzedane"
        ></app-status-badge>
      </li>
    }
  </div>
}
