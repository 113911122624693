import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { isEqual, pick } from 'lodash';
import {
  Observable,
  Subject,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
} from 'rxjs';
import { Order } from 'src/app/core/interfaces/orders/order';
import { MapWaypoint } from 'src/app/core/interfaces/utilities/here-maps/map-waypoint';
import { HereMapsService } from 'src/app/core/services/utilities/here-maps.service';
import { OrdersActions } from 'src/app/core/state/orders/orders.actions';
import { selectOrder } from 'src/app/core/state/orders/orders.selectors';
import { MapComponent } from 'src/app/shared/components/map/map.component';

interface Data {
  uuid: string;
}

@UntilDestroy()
@Component({
  selector: 'app-order-details-dialog',
  templateUrl: './order-details-dialog.component.html',
  styleUrls: ['./order-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailsDialogComponent implements OnInit {
  @ViewChild(MapComponent) map!: MapComponent;

  order$ = this.getOrder$();
  close$ = new Subject<void>();

  constructor(
    @Inject(DIALOG_DATA) protected data: Data,
    protected dialogRef: DialogRef<void>,
    private store: Store,
    private hereMapsService: HereMapsService,
  ) {}

  ngOnInit(): void {
    this.drawRoute();
  }

  drawRoute(): void {
    this.order$
      .pipe(
        map((order): MapWaypoint[] =>
          order.route.map((rp) => pick(rp, ['address', 'completed'])),
        ),
        distinctUntilChanged(isEqual),
        switchMap((waypoints) =>
          this.hereMapsService.findRoute(waypoints, {
            routingMode: 'fast',
            return: ['polyline', 'summary'],
          }),
        ),
        filter((routes) => routes.length !== 0),
        map((routes) => routes[0]),
      )
      .subscribe((route) => {
        this.map.clearRoutes();
        this.map.drawRoute(route, { drawFirst: true });
        this.map.centerContent();
      });
  }

  getOrder$(): Observable<Order> {
    return this.store.pipe(
      select(selectOrder(this.data.uuid)),
      untilDestroyed(this),
      filter((order): order is Order => {
        if (!order) this.close$.next();
        return !!order;
      }),
    );
  }

  editOrder(uuid: string): void {
    this.store.dispatch(OrdersActions.showEditOrderForm({ uuid }));
  }

  deleteOrder(uuid: string): void {
    this.store.dispatch(OrdersActions.deleteOrder({ uuid }));
  }
}
