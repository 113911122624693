import { createFeatureSelector, createSelector } from '@ngrx/store';
import { sortBy, uniqBy } from 'lodash';
import { ZodIssue } from 'zod';
import { OrderStatus } from '../../enums/orders/order-status';
import {
  VehicleStatus,
  getVehicleStatus,
} from '../../enums/vehicles/vehicle-status';
import { VehiclesCount } from '../../enums/vehicles/vehicles-count';
import { Order } from '../../interfaces/orders/order';
import { Badge } from '../../interfaces/utilities/badge';
import { FirebaseSolutionPoint } from '../../interfaces/vehicles/firebase-solution-point';
import {
  FirebaseVehicle,
  FirebaseVehicleSchema,
} from '../../interfaces/vehicles/firebase-vehicle';
import { RoutePoint } from '../../interfaces/vehicles/route-point';
import { Vehicle } from '../../interfaces/vehicles/vehicle';
import { VehiclesFilters } from '../../interfaces/vehicles/vehicles-filters';
import { selectPlannerLoading } from '../planner/planner.selectors';
import { selectSolutions } from '../solutions/solutions.selectors';
import { SolutionsState } from '../solutions/solutions.state';
import { VehiclesState } from './vehicles.state';

export const selectVehiclesState =
  createFeatureSelector<VehiclesState>('vehicles');

export const selectAllVehicles = (orders: Order[] | undefined) =>
  createSelector(
    selectPlannerLoading,
    selectVehiclesState,
    selectSolutions,

    (loading, vehiclesState, solutions): Vehicle[] | undefined => {
      if (loading || orders == null) return undefined;

      return Object.values(vehiclesState).map((firebaseVehicle): Vehicle => {
        const errors = getVehicleErrors(firebaseVehicle);
        const [vehicleOrders, route] = getRoute(
          firebaseVehicle,
          orders,
          solutions,
        );
        const status = getStatus(firebaseVehicle, vehicleOrders, errors);
        const cargoWeight = getCargoWeight(route);

        return {
          ...firebaseVehicle,
          isActive: firebaseVehicle.isActive ?? true,
          errors,
          status,
          orders: vehicleOrders,
          route,
          from: route[0]?.address,
          to: route.length <= 1 ? undefined : route.at(-1)!.address,
          estimatedTime: route.at(-1)?.estimatedTime,
          cargoWeight,
        };
      });
    },
  );

export const selectVehicles = (
  orders: Order[] | undefined,
  options: {
    query: string;
    filters: VehiclesFilters;
  },
) =>
  createSelector(selectAllVehicles(orders), (vehicles) => {
    const { query, filters } = options;

    const filtersChecks: ((order: Vehicle) => boolean)[] = [];

    if (query) {
      const lowerCaseQuery = query.toLowerCase();
      filtersChecks.push(
        (v) =>
          v.uuid?.startsWith(lowerCaseQuery) ||
          v.id?.toLowerCase()?.includes(lowerCaseQuery),
      );
    }

    if (filters.type && filters.type.length !== 0) {
      filtersChecks.push((v) => filters.type!.includes(v.type));
    }

    if (!filters.showAvailable) {
      filtersChecks.push((v) => v.status.code !== VehicleStatus.AVAILABLE);
    }
    if (!filters.showAvailablePlanned) {
      filtersChecks.push(
        (v) => v.status.code !== VehicleStatus.AVAILABLE_PLANNED,
      );
    }
    if (!filters.showInProgress) {
      filtersChecks.push((v) => v.status.code !== VehicleStatus.IN_PROGRESS);
    }
    if (!filters.showInProgressPlanned) {
      filtersChecks.push(
        (v) => v.status.code !== VehicleStatus.IN_PROGRESS_PLANNED,
      );
    }
    if (!filters.showTrailer) {
      filtersChecks.push((v) => v.status.code !== VehicleStatus.TRAILER);
    }
    if (!filters.showInvalid) {
      filtersChecks.push((v) => v.status.code !== VehicleStatus.INVALID);
    }
    if (!filters.showInactive) {
      filtersChecks.push((v) => v.status.code !== VehicleStatus.INACTIVE);
    }

    return vehicles?.filter((vehicle) =>
      filtersChecks.every((filter) => filter(vehicle)),
    );
  });

export const selectVehicle = (
  orders: Order[] | undefined,
  vehicleUuid: string,
) =>
  createSelector(
    selectAllVehicles(orders),
    (vehicles) => vehicles?.find((v) => v.uuid === vehicleUuid),
  );

export const selectVehiclesCount = (orders: Order[] | undefined) =>
  createSelector(selectAllVehicles(orders), (vehicles) => {
    if (!vehicles) return undefined;

    const count: VehiclesCount = Object.fromEntries(
      Object.values(VehicleStatus).map((status) => [status, 0]),
    ) as VehiclesCount;

    for (const vehicle of vehicles) {
      ++count[vehicle.status.code];
    }

    return count;
  });

const getVehicleErrors = (vehicle: FirebaseVehicle): ZodIssue[] | undefined => {
  const result = FirebaseVehicleSchema.safeParse(vehicle);
  if (result.success) return undefined;

  const errors = result.error.issues;
  return uniqBy(errors, (error) => error.message);
};

const getRoute = (
  vehicle: FirebaseVehicle,
  orders: Order[],
  solutions: SolutionsState,
): [Order[], RoutePoint[]] => {
  const vehicleOrders = orders.filter(
    (order) =>
      order.vehicle?.uuid === vehicle.uuid &&
      order.status.code !== OrderStatus.FINISHED,
  );

  const solution: FirebaseSolutionPoint[] | undefined = solutions[vehicle.uuid];
  if (!solution) return [vehicleOrders, []];

  const route = solution
    .map((p) => {
      const order = vehicleOrders.find((o) => o.uuid === p.orderUuid);
      return order?.route[p.pickup ? 0 : 1];
    })
    .filter((rp): rp is RoutePoint => !!rp);

  return [
    sortBy(vehicleOrders, (order) =>
      route.findIndex((rp) => rp.orderUuid === order.uuid),
    ),
    route,
  ];
};

const getStatus = (
  vehicle: FirebaseVehicle,
  vehicleOrders: Order[],
  errors?: ZodIssue[],
): Badge<VehicleStatus> => {
  const { planned, inProgress } = vehicleOrders.reduce(
    (summary, order) => {
      if (order.status.code === OrderStatus.AWAITING_APPROVAL) {
        summary.planned++;
      } else if (order.status.code === OrderStatus.IN_PROGRESS) {
        summary.inProgress++;
      }
      return summary;
    },
    { planned: 0, inProgress: 0 },
  );

  if (vehicle.isActive === false) {
    // Skip subsequent checks, return inactive status at the end of the function.
  } else if (vehicle.isTrailer) {
    return getVehicleStatus(VehicleStatus.TRAILER);
  } else if (errors) {
    return getVehicleStatus(VehicleStatus.INVALID);
  } else if (inProgress === 0 && planned === 0) {
    return getVehicleStatus(VehicleStatus.AVAILABLE);
  } else if (inProgress === 0 && planned !== 0) {
    return getVehicleStatus(VehicleStatus.AVAILABLE_PLANNED);
  } else if (inProgress !== 0 && planned === 0) {
    return getVehicleStatus(VehicleStatus.IN_PROGRESS);
  } else if (inProgress !== 0 && planned !== 0) {
    return getVehicleStatus(VehicleStatus.IN_PROGRESS_PLANNED);
  }

  return getVehicleStatus(VehicleStatus.INACTIVE);
};

const getCargoWeight = (route: RoutePoint[]): number => {
  return route.reduce((cargoWeight, rp) => {
    if (!rp.completed) return cargoWeight;

    const weight = rp.order.weight;
    if (rp.pickup) {
      cargoWeight += weight;
    } else {
      cargoWeight -= weight;
    }

    return cargoWeight;
  }, 0);
};
