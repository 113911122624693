import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { DictionaryCode } from 'src/app/core/enums/utilities/dictionary-code';
import { VehicleEditMode } from 'src/app/core/enums/vehicles/vehicle-edit-type';
import { VehicleType } from 'src/app/core/enums/vehicles/vehicle-type';
import { Dimensions } from 'src/app/core/interfaces/common/dimensions';
import { VehicleEditData } from 'src/app/core/interfaces/vehicles/vehicle-edit-data';
import { DictionariesService } from 'src/app/core/services/api/dictionaries.service';
import { VehiclesActions } from 'src/app/core/state/vehicles/vehicles.actions';

@Component({
  selector: 'app-vehicle-edit-form-dialog',
  templateUrl: './vehicle-edit-form-dialog.component.html',
  styleUrls: ['./vehicle-edit-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleEditFormDialogComponent implements OnInit {
  readonly VehicleEditMode = VehicleEditMode;

  form!: FormGroup;
  vehicleTypes$ = this.dictionariesService.getDictionary(
    DictionaryCode.VEHICLE_TYPE
  );

  close$ = new Subject<void>();

  constructor(
    @Inject(DIALOG_DATA) protected data: VehicleEditData,
    protected dialogRef: DialogRef<void>,
    private fb: NonNullableFormBuilder,
    private dictionariesService: DictionariesService,
    private store: Store
  ) {}

  ngOnInit(): void {
    const { mode, data } = this.data;
    switch (mode) {
      case VehicleEditMode.TYPE:
        return this.createTypeForm(data?.type);
      case VehicleEditMode.WEIGHT_CAPACITY:
        return this.createWeightCapacityForm(data?.weightCapacity);
      case VehicleEditMode.CARGO_SPACE_CAPACITY:
        return this.createCargoSpaceCapacityForm(data?.cargoSpaceCapacity);
    }
  }

  submit(): void {
    if (this.form.invalid) return;

    this.store.dispatch(
      VehiclesActions.editVehicle({
        uuid: this.data.vehicleUuid,
        value: this.form.getRawValue(),
      })
    );
    this.close$.next();
  }

  createTypeForm(type?: VehicleType): void {
    this.form = this.fb.group({
      type: this.fb.control<VehicleType | null>(type ?? null, [
        Validators.required,
      ]),
    });
  }

  createWeightCapacityForm(weightCapacity?: number): void {
    this.form = this.fb.group({
      weightCapacity: this.fb.control<number | null>(weightCapacity ?? null, [
        Validators.required,
        Validators.min(Number.EPSILON),
      ]),
    });
  }

  createCargoSpaceCapacityForm(cargoSpaceCapacity?: Dimensions): void {
    this.form = this.fb.group({
      cargoSpaceCapacity: this.fb.group({
        depth: this.fb.control<number | null>(
          cargoSpaceCapacity?.depth ?? null,
          [Validators.required, Validators.min(Number.EPSILON)]
        ),
        width: this.fb.control<number | null>(
          cargoSpaceCapacity?.width ?? null,
          [Validators.required, Validators.min(Number.EPSILON)]
        ),
        height: this.fb.control<number | null>(
          cargoSpaceCapacity?.height ?? null,
          [Validators.required, Validators.min(Number.EPSILON)]
        ),
      }),
    });
  }
}
