@if (order$ | async; as order) {
  <app-dialog
    type="right"
    [width]="400"
    [close$]="close$"
    (close)="dialogRef.close()"
  >
    <div class="title" title>
      <app-status-badge type="dot" [status]="order.status"></app-status-badge>
      <h3>{{ order.id }}</h3>
    </div>

    <div class="options" title-options>
      <app-icon
        class="option"
        icon="edit"
        [width]="16"
        (click)="editOrder(order.uuid)"
      ></app-icon>

      <app-icon
        class="option"
        icon="delete"
        [width]="16"
        (click)="deleteOrder(order.uuid)"
      ></app-icon>
    </div>

    @if (order.errors; as errors) {
      <div class="errors">
        @for (error of order.errors; track error.message) {
          <p>
            {{ "orderError." + error.message | translate }}
          </p>
        }
      </div>
    }
    @if (order.route; as route) {
      <app-route [route]="route"></app-route>
    }
    <app-order-details [order]="order"></app-order-details>
    <app-map [disabled]="true"></app-map>
  </app-dialog>
}
