import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  Observable,
  Subject,
  filter,
  first,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
  zip,
} from 'rxjs';
import { CLIENT_KEY } from '../../constants/utilities/storage-keys';
import { UserRole, getUserRoleRank } from '../../enums/auth/user-role';
import { LoginForm } from '../../interfaces/auth/login-form';
import { User } from '../../interfaces/auth/user';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService {
  logout$ = new Subject<void>();

  constructor(private fireAuth: AngularFireAuth) {}

  login(form: LoginForm): Observable<User> {
    return from(
      this.fireAuth.signInWithEmailAndPassword(form.email, form.password),
    ).pipe(
      switchMap(() => this.getUser()),
      filter((user): user is User => user != null),
      tap((user) => {
        if (getUserRoleRank(user.role) >= getUserRoleRank(UserRole.MODERATOR)) {
          return;
        }

        const userClient = user.client.toLowerCase();
        const formClient = form.client.toLowerCase();

        if (userClient !== formClient) throw new Error();
      }),
    );
  }

  logout(): Observable<void> {
    return from(this.fireAuth.signOut()).pipe(tap(() => this.logout$.next()));
  }

  getUser(): Observable<User | undefined> {
    return this.fireAuth.user.pipe(
      first(),
      mergeMap((user) =>
        user == null
          ? of(undefined)
          : zip(of(user), from(user.getIdTokenResult())),
      ),
      map((data) => {
        if (!data) return undefined;

        const [user, tokenResult] = data;
        return {
          uid: user.uid,
          email: user.email!,
          client: tokenResult.claims['client'],
          role: tokenResult.claims['role'],
          displayName: user.displayName,
          phoneNumber: user.phoneNumber,
          lastSignInTime: user.metadata.lastSignInTime ?? null,
          fireTmsApiKey: tokenResult.claims['fireTmsApiKey'] ?? null,
          token: tokenResult.token,
        };
      }),
    );
  }

  getIdToken(): Observable<string | null> {
    return this.fireAuth.idToken.pipe(take(1));
  }

  sendPasswordResetEmail(email: string): Observable<void> {
    return from(
      this.fireAuth.sendPasswordResetEmail(email, {
        url: `${window.location.origin}/login`,
      }),
    );
  }

  verifyResetPasswordCode(code: string): Observable<void> {
    return from(this.fireAuth.verifyPasswordResetCode(code)).pipe(
      switchMap(() => of(void 0)),
    );
  }

  resetPassword(code: string, newPassword: string): Observable<void> {
    return from(this.fireAuth.confirmPasswordReset(code, newPassword));
  }

  getClient(): string | undefined {
    return localStorage.getItem(CLIENT_KEY) ?? undefined;
  }

  setClient(client: string): void {
    localStorage.setItem(CLIENT_KEY, client);
  }
}
