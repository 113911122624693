import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DictionaryCode } from 'src/app/core/enums/utilities/dictionary-code';
import { Order } from 'src/app/core/interfaces/orders/order';
import { OrdersActions } from 'src/app/core/state/orders/orders.actions';

interface Reason {
  number: number;
  name: string;
}

@Component({
  selector: 'app-order-unnassignment-reasons',
  templateUrl: './order-unnassignment-reasons.component.html',
  styleUrls: ['./order-unnassignment-reasons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderUnnassignmentReasonsComponent {
  public order = input.required<Order>();

  protected reasons = computed((): Reason[] =>
    Object.entries(this.order().unassignmentReasons ?? {}).reduce(
      (reasons: Reason[], [key, value], index) => {
        if (value) {
          reasons.push({
            number: index + 1,
            name: this.translateService.instant(
              `${DictionaryCode.UNNASIGNMENT_REASON}.${key}`,
            ),
          });
        }
        return reasons;
      },
      [],
    ),
  );

  protected message = computed((): string => {
    const reasonsMessage = this.reasons()
      .map((r) => r.name)
      .join('; ');

    const rejectedVehicles = this.order().rejectedVehicles;
    if (!rejectedVehicles?.length) return reasonsMessage;

    const rejectedVehiclesMessage = `Odrzucone pojazdy: ${rejectedVehicles.join(
      ', ',
    )}`;
    return `${reasonsMessage} <br/> ${rejectedVehiclesMessage}`;
  });

  constructor(
    private translateService: TranslateService,
    private store: Store,
  ) {}

  protected clearRejections(): void {
    this.store.dispatch(
      OrdersActions.clearRejections({
        orderParentUuid: this.order().parentUuid,
      }),
    );
  }
}
