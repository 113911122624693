<app-dialog
  type="center"
  [width]="640"
  [close$]="close$"
  (close)="dialogRef.close()"
>
  <h3 title>
    @switch (data.mode) {
      @case ("add") {
        Dodaj zlecenie
      }
      @case ("edit") {
        Edytuj zlecenie
      }
    }
  </h3>

  <form [formGroup]="form">
    <div class="section details">
      <h4>Szczegóły zlecenia</h4>

      <app-input
        color="gray"
        formControlName="id"
        label="ID zlecenia"
        placeholder="ID zlecenia"
      ></app-input>
      <app-input
        color="gray"
        formControlName="client"
        label="Klient"
        placeholder="Nazwa"
      ></app-input>

      <app-form-group
        color="gray"
        label="Stawka"
        width="100%"
        [formControls]="[form.get('price')!, form.get('currency')!]"
        (labelCallback)="priceInput.focus()"
      >
        <app-input
          color="gray"
          formControlName="price"
          [float]="true"
          placeholder="0.00"
          width="calc(100% - 80px)"
          #priceInput
        ></app-input>

        <app-dropdown
          color="gray"
          formControlName="currency"
          [options]="currencies$ | async"
          width="80px"
        ></app-dropdown>
      </app-form-group>

      <app-dropdown
        color="gray"
        formControlName="transportType"
        label="Rodzaj transportu"
        [options]="transportTypes$ | async"
        width="140px"
      ></app-dropdown>
    </div>

    <div class="section vehicle-and-cargo">
      <h4>Pojazd i ładunek</h4>

      <app-dropdown
        class="stretch"
        color="gray"
        formControlName="vehicleType"
        label="Typ pojazdu"
        [options]="vehicleTypes$ | async"
        [multiple]="true"
      ></app-dropdown>

      <app-input
        color="gray"
        class="quantity-input"
        formControlName="quantity"
        [integer]="true"
        label="Ilość towarów"
        placeholder="0"
        textAlign="center"
      >
        <button type="button" [tabindex]="-1" (click)="changeQuantity(-1)">
          <app-icon icon="zoom-out" [width]="10" [height]="2"></app-icon>
        </button>

        <button type="button" [tabindex]="-1" (click)="changeQuantity(1)">
          <app-icon icon="zoom-in" [size]="10"></app-icon>
        </button>
      </app-input>

      <app-input
        formControlName="weight"
        color="gray"
        [integer]="true"
        label="Łączna waga"
        placeholder="0"
        unit="kg"
      ></app-input>
    </div>

    <div class="section loading-point">
      <h4>Załadunek</h4>

      <app-address-input
        color="gray"
        class="stretch"
        formControlName="pickupAddress"
        label="Adres załadunku"
      ></app-address-input>

      <app-form-group>
        <app-datepicker
          color="gray"
          formControlName="pickupDateStart"
          label="Data (od)"
          placeholder="Data"
          width="50%"
        ></app-datepicker>

        <p class="input-separator">-</p>

        <app-datepicker
          color="gray"
          formControlName="pickupDateEnd"
          label="Data (do)"
          placeholder="Data"
          width="50%"
        ></app-datepicker>
      </app-form-group>
      <app-form-group>
        <app-timepicker
          color="gray"
          formControlName="pickupTimeStart"
          label="Godzina (od)"
          placeholder="Godz."
          width="50%"
        ></app-timepicker>

        <p class="input-separator">-</p>

        <app-timepicker
          color="gray"
          formControlName="pickupTimeEnd"
          label="Godzina (do)"
          placeholder="Godz."
          width="50%"
        ></app-timepicker>
      </app-form-group>
    </div>

    <div class="section unloading-point">
      <h4>Rozładunek</h4>

      <app-address-input
        color="gray"
        class="stretch"
        formControlName="deliveryAddress"
        label="Adres rozładunku"
      ></app-address-input>

      <app-form-group>
        <app-datepicker
          color="gray"
          formControlName="deliveryDateStart"
          label="Data (od)"
          placeholder="Data"
          width="50%"
        ></app-datepicker>

        <p class="input-separator">-</p>

        <app-datepicker
          color="gray"
          formControlName="deliveryDateEnd"
          label="Data (do)"
          placeholder="Data"
          width="50%"
        ></app-datepicker>
      </app-form-group>
      <app-form-group>
        <app-timepicker
          color="gray"
          formControlName="deliveryTimeStart"
          label="Godzina (od)"
          placeholder="Godz."
          width="50%"
        ></app-timepicker>

        <p class="input-separator">-</p>

        <app-timepicker
          color="gray"
          formControlName="deliveryTimeEnd"
          label="Godzina (do)"
          placeholder="Godz."
          width="50%"
        ></app-timepicker>
      </app-form-group>
    </div>
  </form>

  <div class="button">
    <app-button [disabled]="form.invalid || form.disabled" (click)="submit()">
      @switch (data.mode) {
        @case ("add") {
          Dodaj
        }
        @case ("edit") {
          Zapisz
        }
      }
    </app-button>
  </div>
</app-dialog>
