import { DateTime } from 'luxon';
import { z } from 'zod';
import { TransportType } from '../../enums/orders/transport-type';
import { zodErrorMessage } from '../../helpers/zod-error-message';
import { getAddressSchema } from '../common/address';
import { getDimensionsSchema } from '../common/dimensions';
import { getTimeWindowSchema } from '../common/time-window';

export const FirebaseOrderSchema = z
  .object({
    uuid: z.string(zodErrorMessage('uuid.required')).uuid('uuid.invalid'),
    parentUuid: z
      .string(zodErrorMessage('parentUuid.required'))
      .uuid('parentUuid.invalid'),
    weight: z
      .number(zodErrorMessage('weight.required'))
      .int('weight.invalid')
      .nonnegative('weight.invalid'),
    quantity: z
      .number(zodErrorMessage('quantity.required'))
      .int('quantity.invalid')
      .positive('quantity.invalid'),
    size: getDimensionsSchema('size.required', 'size.invalid'),
    from: getAddressSchema('from.required', 'from.invalid'),
    to: getAddressSchema('to.required', 'to.invalid'),
    pickupTimeWindow: getTimeWindowSchema(
      'pickupTimeWindow.required',
      'pickupTimeWindow.invalid',
    ),
    deliveryTimeWindow: getTimeWindowSchema(
      'deliveryTimeWindow.required',
      'deliveryTimeWindow.invalid',
    ),
    vehicleType: z
      .array(z.string(zodErrorMessage('vehicleType.required')))
      .min(1, 'vehicleType.required'),
    distance: z
      .number(zodErrorMessage('distance.required'))
      .int('distance.invalid')
      .nonnegative('distance.invalid'),
    id: z.string(zodErrorMessage('id.required')).min(1, 'id.required'),
    transportType: z.nativeEnum(
      TransportType,
      zodErrorMessage('transportType.required'),
    ),
    loadingTime: z
      .number(zodErrorMessage('loadingTime.invalid'))
      .int('loadingTime.invalid')
      .nonnegative('loadingTime.invalid')
      .default(0),
    unloadingTime: z
      .number(zodErrorMessage('unloadingTime.invalid'))
      .int('unloadingTime.invalid')
      .nonnegative('unloadingTime.invalid')
      .default(0),
    rotatable: z.boolean(zodErrorMessage('rotatable.invalid')).default(true),
    assigned: z.boolean(zodErrorMessage('assigned.invalid')).default(false),
    loadingStarted: z
      .boolean(zodErrorMessage('loadingStarted.invalid'))
      .default(false),
    loadingStart: z
      .string(zodErrorMessage('loadingStart.invalid'))
      .datetime('loadingStart.invalid')
      .optional(),
    wasLoaded: z.boolean(zodErrorMessage('wasLoaded.invalid')).default(false),
    unloadingStarted: z
      .boolean(zodErrorMessage('unloadingStarted.invalid'))
      .default(false),
    unloadingStart: z
      .string(zodErrorMessage('unloadingStart.invalid'))
      .datetime('unloadingStart.invalid')
      .optional(),
    wasUnloaded: z
      .boolean(zodErrorMessage('wasUnloaded.invalid'))
      .default(false),
    wasSold: z.boolean(zodErrorMessage('wasSold.invalid')).default(false),
    assignedVehicleUuid: z
      .string(zodErrorMessage('assignedVehicleUuid.invalid'))
      .uuid('assignedVehicleUuid.invalid')
      .optional(),
    assignedVehicleId: z
      .string(zodErrorMessage('assignedVehicleId.invalid'))
      .min(1, 'assignedVehicleId.invalid')
      .optional(),
    assignedByOnyx: z
      .boolean(zodErrorMessage('assignedByOnyx.invalid'))
      .default(false),
    price: z
      .number(zodErrorMessage('price.invalid'))
      .nonnegative('price.invalid')
      .optional(),
    currency: z.string(zodErrorMessage('currency.invalid')).optional(),
    client: z.string(zodErrorMessage('client.invalid')).optional(),
    additionalInformation: z
      .string(zodErrorMessage('additionalInformation.invalid'))
      .optional(),
  })
  .refine(
    (order) => {
      if (order.wasLoaded) return true;

      let pickupTime = DateTime.fromJSDate(
        new Date(order.pickupTimeWindow.end),
      );
      pickupTime = pickupTime.plus({
        seconds: order.loadingStarted ? order.loadingTime || 0 : 0,
      });

      return pickupTime >= DateTime.now();
    },
    { path: ['pickupTimeWindow'], message: 'pickupTimeWindow.inactive' },
  )
  .refine(
    (order) => {
      if (order.wasUnloaded) return true;

      let deliveryTime = DateTime.fromJSDate(
        new Date(order.deliveryTimeWindow.end),
      );
      deliveryTime = deliveryTime.plus({
        seconds: order.unloadingStarted ? order.loadingTime || 0 : 0,
      });

      return deliveryTime >= DateTime.now();
    },
    { path: ['deliveryTimeWindow'], message: 'deliveryTimeWindow.inactive' },
  );

export type FirebaseOrder = z.infer<typeof FirebaseOrderSchema>;
