import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrderAssignment } from 'src/app/core/enums/orders/order-assignment';
import { OrderStatus } from 'src/app/core/enums/orders/order-status';
import { Order } from 'src/app/core/interfaces/orders/order';
import { selectClient } from 'src/app/core/state/auth/auth.selectors';

@Component({
  selector: 'app-order-assignment',
  templateUrl: './order-assignment.component.html',
  styleUrls: ['./order-assignment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderAssignmentComponent {
  protected readonly OrderAssignment = OrderAssignment;
  protected readonly OrderStatus = OrderStatus;

  public type = input<'primary' | 'secondary'>('primary');
  public assignment = input.required<Order['assignment']>();
  public status = input.required<OrderStatus>();

  protected client = this.store.selectSignal(selectClient);

  constructor(private store: Store) {}
}
